<template>
  <div>
    <vx-card
      :title="`Contrats (${
        allProjects.list.filter((el) => el.status == 'active').length
      } actifs  / ${
        allProjects.list.filter((el) => el.status == 'inactive').length
      } inactifs)`"
    >
      <div class="w-auto" v-if="loading">
        <clip-loader :loading="loading" color="primary"></clip-loader>
      </div>
      <div v-else class="">
        <div class="pt-2 pb-4 aab-vie-filter">
          <div class="pt-2 pb-2">
            <vs-collapse>
              <vs-collapse-item class="aab-vie-collapse-item">
                <div slot="header">
                  <div class="aab-vie-collapse-filter">Filtrage</div>
                </div>
                <div class="p-3">
                  <vs-row vs-w="12" vs-align="center">
                    <vs-col
                      class="pr-2 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <span class="pl-1 pr-1">Du</span>
                      <div
                        class="inputx w-full flex justify-around"
                        style="align-items: center"
                      >
                        <vs-input
                          v-model="startDate"
                          type="date"
                          class="aab-vie-disable-input-date inputx w-full mb-2 mt-2"
                        />
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-2 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <span class="pl-1 pr-1">Au </span>
                      <div
                        class="inputx w-full flex justify-around"
                        style="align-items: center"
                      >
                        <vs-input
                          type="date"
                          v-model="endDate"
                          :min="startDate"
                          class="aab-vie-disable-input-date inputx w-full mb-2 mt-2"
                        />
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <span class="pl-2">Avis de souscription</span>
                        <multiselect
                          v-model="notice"
                          :options="noticeList"
                          :close-on-select="true"
                          placeholder="--Choisir un avis de souscription--"
                          label="label"
                          track-by="label"
                          :selectLabel="global.selectLabel"
                          :selectedLabel="global.selectedLabel"
                          :deselectLabel="global.deselectLabel"
                        >
                        </multiselect>
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <span class="pl-2">Catégorie socioprofessionnelle</span>
                        <multiselect
                          v-model="categorySocPro"
                          :options="allCategorySocPro"
                          :close-on-select="true"
                          placeholder="--Choisir une catégorie-socioprofessionnelle--"
                          label="label"
                          track-by="label"
                          :selectLabel="global.selectLabel"
                          :selectedLabel="global.selectedLabel"
                          :deselectLabel="global.deselectLabel"
                        >
                        </multiselect>
                      </div>
                    </vs-col>

                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <span class="pl-2">Situation familiale</span>
                        <multiselect
                          v-model="familySituation"
                          :options="allFamilySituation"
                          :close-on-select="true"
                          placeholder="--Choisir une situation familiale--"
                          label="label"
                          track-by="label"
                          :selectLabel="global.selectLabel"
                          :selectedLabel="global.selectedLabel"
                          :deselectLabel="global.deselectLabel"
                        >
                        </multiselect>
                      </div>
                    </vs-col>

                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <span class="pl-2">Etat</span>
                        <multiselect
                          v-model="projectStatus"
                          :options="allStatus"
                          :close-on-select="true"
                          placeholder="--Choisir un etat--"
                          label="label"
                          track-by="label"
                          :selectLabel="global.selectLabel"
                          :selectedLabel="global.selectedLabel"
                          :deselectLabel="global.deselectLabel"
                        >
                        </multiselect>
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4 mb-2 mt-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <span class="pl-2">Produit</span>
                        <multiselect
                          v-model="projetProduct"
                          :options="allProduct"
                          :close-on-select="true"
                          placeholder="--Choisir un produit--"
                          label="label"
                          track-by="label"
                          :selectLabel="global.selectLabel"
                          :selectedLabel="global.selectedLabel"
                          :deselectLabel="global.deselectLabel"
                        >
                        </multiselect>
                      </div>
                    </vs-col>
                  </vs-row>
                  <vs-row vs-w="12" vs-align="center" vs-justify="center">
                    <vs-col
                      class="pt-4 pb-2"
                      vs-type="flex"
                      vs-align="center"
                      vs-justify="center"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <vs-button @click="resetFilter">
                        Réinitialiser
                      </vs-button>
                    </vs-col>
                  </vs-row>
                </div>
              </vs-collapse-item>
            </vs-collapse>
          </div>
        </div>

        <div>
          <vs-prompt
            title="Exportation"
            class="export-options"
            @cancel="clearFields"
            @accept="exportToData"
            accept-text="Valider"
            cancel-text="Annuler"
            @close="clearFields"
            :active.sync="activePrompt"
          >
            <vs-input
              v-model="fileName"
              placeholder="Entrez le nom du fichier..."
              class="w-full"
            />
            <multiselect
              v-model="selectedFormat"
              :options="formats"
              class="my-4"
              placeholder="--Choisir--"
              :selectLabel="global.selectLabel"
              :selectedLabel="global.selectedLabel"
              :deselectLabel="global.deselectLabel"
            >
              <template v-slot:noOptions>
                <span>{{ global.selectNotFoundData }}</span>
              </template>
            </multiselect>

            <div class="flex">
              <span class="mr-4">Largeur automatique de cellule:</span>
              <vs-switch v-model="cellAutoWidth"
                >Largeur automatique de cellule</vs-switch
              >
            </div>
          </vs-prompt>
          <div
            class="flex flex-wrap-reverse items-center data-list-btn-container"
          >
            <div>
              <div>
                <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
                  <div
                    class="p-3 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full"
                  >
                    <span class="mr-2">Afficher {{ limit }}</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>
                  <vs-dropdown-menu>
                    <vs-dropdown-item
                      :key="i"
                      v-for="(r, i) in rowsTable"
                      @click="limit = r"
                    >
                      <span class="flex items-center">
                        <span>{{ r }}</span>
                      </span>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>
            </div>
            <div>
              <!--<vs-dropdown
                vs-trigger-click
                class="dd-actions cursor-pointer ml-4"
              >
                <div
                  class="p-3 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full"
                >
                  <span class="mr-2">Actions</span>
                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                  <vs-dropdown-item @click="activePrompt = true">
                    <span class="flex items-center">
                      <feather-icon
                        icon="PrinterIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>Exporter</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown> -->
            </div>
          </div>
          <div class>
            <div class="w-auto" v-if="loadingForTable">
              <clip-loader
                :loading="loadingForTable"
                color="primary"
              ></clip-loader>
            </div>
            <div v-else>
              <div v-if="allProjects.list.length !== 0">
                <vs-table
                  @selected="activeCorrectTabs"
                  v-model="contratSelected"
                  :data="allProjects.list"
                  search
                  stripe
                  pagination
                  :max-items="limit != 'Tout' ? limit : total"
                  noDataText="Aucun résultat trouvé"
                >
                  <template slot="thead">
                    <vs-th>N°</vs-th>
                    <vs-th sort-key="identity">Identité</vs-th>
                    <vs-th sort-key="productName">Produit</vs-th>
                    <vs-th sort-key="notice">Avis souscription</vs-th>
                    <vs-th sort-key="refContrat">Référence</vs-th>
                    <vs-th sort-key="quote"
                      >Période</vs-th
                    >
                    <vs-th sort-key="status">Etat</vs-th>
                  </template>
                  <template slot-scope="{ data }">
                    <vs-tr
                      :data="tr"
                      :key="indextr"
                      v-for="(tr, indextr) in data"
                    >
                      <vs-td :data="data[indextr].id">{{ indextr + 1 }}</vs-td>
                      <vs-td :data="data[indextr].identity">
                        {{ data[indextr].identity }}
                      </vs-td>
                      <vs-td :data="data[indextr].productName">{{
                        productList.find(
                          (pro) => pro.id == data[indextr].productName
                        ).label
                      }}</vs-td>
                      <vs-td :data="data[indextr].notice">{{
                        noticeList.find(
                          (notice) => notice.id == data[indextr].notice
                        ).label
                      }}</vs-td>
                      <vs-td :data="data[indextr].refContrat">{{
                        data[indextr].refContrat
                      }}</vs-td>

                      <vs-td :data="data[indextr].avenants">
                        <div v-if="data[indextr].avenants.length > 0">
                          {{
                            ` ${formatDate(
                              data[indextr].avenants.find(
                                (av) => av.isDefault == true
                              ).startDateContributions
                            )} - ${formatDate(
                              data[indextr].avenants[
                                data[indextr].avenants.length - 1
                              ].endDateContributions
                            )}`
                          }}
                        </div>
                        <div v-else-if="data[indextr].quittances.length > 0">
                          {{
                            ` ${formatDate(
                              data[indextr].quittances.find(
                                (quit) => quit.isDefault == true
                              ).startDateContributions
                            )} / ${formatDate(
                              data[indextr].quittances[
                                data[indextr].quittances.length - 1
                              ].endDateContributions
                            )}`
                          }}
                        </div>
                      </vs-td>
                      <vs-td :data="data[indextr].status">{{
                        allStatus.find((pro) => pro.id == data[indextr].status)
                          ? allStatus.find(
                              (pro) => pro.id == data[indextr].status
                            ).label
                          : ""
                      }}</vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
                <vs-pagination
                  class="mt-6"
                  :total="allPages"
                  v-model="currentPage"
                ></vs-pagination>

                <vs-popup
                  class="holamundo"
                  :title="
                    contratSelected.subscription ? `${contratSelected.subscription.subscriber.firstname} ${contratSelected.subscription.subscriber.lastname}`
                    : ''
                  "
                  fullscreen
                  :active.sync="popupAllProduct"
                >
                  <div v-if="contratSelected.productName == 'ABQ'">
                      <aab-vie-abq
                        v-on:onDisplayPopup="onDisplayPopup"
                        :dataInfo="contratSelected ? contratSelected : {}"
                      ></aab-vie-abq>
                    </div>
                    <div v-else-if="contratSelected.productName == 'APF'">
                      <aab-vie-apf
                        v-on:onDisplayPopup="onDisplayPopup"
                        :dataInfo="contratSelected ? contratSelected : {}"
                      ></aab-vie-apf>
                    </div>
                    <div v-else-if="contratSelected.productName == 'AAF'">
                      <aab-vie-aaf
                        v-on:onDisplayPopup="onDisplayPopup"
                        :dataInfo="contratSelected ? contratSelected : {}"
                      ></aab-vie-aaf>
                    </div>

                    <div v-else-if="contratSelected.productName == 'AHR'">
                      <aab-vie-ahr
                        v-on:onDisplayPopup="onDisplayPopup"
                        :crrModuleName="contratSelected.productName"
                        :item="contratSelected.id"
                      ></aab-vie-ahr>
                    </div>

                    <div v-else-if="contratSelected.productName == 'AAE'">
                      <aab-vie-aae
                        v-on:onDisplayPopup="onDisplayPopup"
                        :crrModuleName="contratSelected.productName"
                        :item="contratSelected.id"
                      ></aab-vie-aae>
                    </div>
                </vs-popup>
              </div>
              <div v-else>
                <div class="flex items-center justify-center mt-8">
                  <strong>Aucun enregistrement</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { en, fr } from "vuejs-datepicker/dist/locale";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { mixin } from "@/mixins/mixin";
import moment from "@/helpers/moment";
import role from "@/config/role";
import global from "@/config/global";
import Multiselect from "vue-multiselect";

export default {
  name: "aab-vie-contrat",
  components: {
    ClipLoader,
    Multiselect,
  },
  data() {
    return {
      activeTab: 2,
      loadingForTable: false,
      contratSelected: {},
      popupAllProduct: false,
      isResetFilter: false,
      notice: "",
      projetProduct: "",
      categorySocPro: "",
      projectStatus: "",
      //profession: "",
      familySituation: "",
      startDate: "",
      endDate: "",
      product: "",
      allStatus: [
        { id: "active", label: "Actif" },
        { id: "inactive", label: "Inactif" },
      ],
      intermediariesList: [],
      intermediary: "",
      loading: false,
      global: {},
      //Pagination
      limit: 1,
      skip: 0,
      currentPage: 1,
      total: 0,
      allPages: 1,
      //Pagination
      //export section
      fileName: "",
      formats: ["xlsx", "csv", "txt"],//formats: ["xlsx", "csv", "txt", "pdf"],
      cellAutoWidth: true,
      selectedFormat: "xlsx",
      headerTitle: ["N°", "Nom", "Prénom", "Nom d'utilisateur", "Email"],
      headerVal: ["index", "lastname", "firstname", "username", "status"],
      activePrompt: false,
      //end export section
      projectSelected: [],
      crrIDs: [],
    };
  },

  created() {
    this.role = role;
    this.global = global;
    Validator.localize("fr", VeeValidateFR);
    this.productList = this.allProduct;
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("subscribe", ["allProjects"]),
    ...mapGetters("general", [
      "rowsTable",
      "allFamilySituation",
      "allProduct",
      "noticeList",
      "allCategorySocPro",
    ]),
  },
  mounted() {
    this.crrModuleName = this.$route.params.crrModuleName;
    this.limit = this.rowsTable[1];
  },

  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapActions("users", ["applyGetIntermediariesSys"]),
    ...mapActions("subscribe", [
      "applyGetAllProject",
      "applyGetOngoingProject",
      "applyPutOngoingProject",
      "applyRemoveOngoingProject",
      "applyRelaunchUser",
    ]),
    displayStatus(status) {
      return this.allStatus.find((el) => el.id == status).label;
    },
    onDisplayPopup(isClose) {
      this.popupAllProduct = isClose;
    },
    checkViewProject(item) {},
    // async getIntermediarySys() {
    //   let data = {
    //     limit: "Tout",
    //     skip: 0,
    //     type: [
    //       "broker_inspector",
    //       "broker_leader",
    //       "broker_merchant",
    //       "broker_general_agent",
    //       "broker"
    //     ]
    //   };
    //   this.loading = true;

    //   try {
    //     let intermediary = await this.applyGetIntermediariesSys(data);
    //     intermediary.list.forEach(el => {
    //       this.intermediariesList.push({
    //         id: el.id,
    //         fullname: el.person.firstname + el.person.lastname
    //       });
    //     });

    //     this.loading = false;
    //     this.error = false;
    //     this.errorMsg = "";
    //   } catch (err) {
    //     this.loading = false;
    //     this.error = true;
    //     this.errorMsg = "";

    //     if (err.response) {
    //       if (
    //         err.response.status &&
    //         err.response.statusText === "Unauthorized"
    //       ) {
    //         this.acceptAlert(
    //           "danger",
    //           "Authentification",
    //           "Votre session a expirée"
    //         );
    //         await this.logout();
    //         this.$router.push({ name: "Login" });
    //       } else {
    //         err.response.data.errors.forEach(item => {
    //           switch (item.code) {
    //             case "userNotFound":
    //               this.errorMsg = "Votre nom d'utilisateur n'existe pas";
    //               this.acceptAlert("danger", "Utilisateurs", this.errorMsg);
    //               break;

    //             default:
    //               this.acceptAlert(
    //                 "danger",
    //                 "Utilisateurs",
    //                 "Une erreur s'est produite"
    //               );
    //               break;
    //           }
    //         });
    //       }
    //     }
    //   }
    // },
    resetFilter() {
      this.isResetFilter = true;
      this.startDate = "";
      this.endDate = "";
      this.projetProduct = "";
      this.projectStatus = "";
      this.categorySocPro = "";
      this.familySituation = "";
      this.notice = "";
      this.isResetFilter = false;

      this.fetchApplyGetAllProject({
        limit: this.limit,
        skip: this.skip,
        categorySocioProfessional: this.categorySocPro.id
          ? this.categorySocPro.id
          : "",
        notice: this.notice.id ? this.notice.id : "",
        // profession: this.profession,
        startDate: this.startDate,
        endDate: this.endDate,
        familySituation: this.familySituation.id ? this.familySituation.id : "",
        productName: this.projetProduct.id ? this.projetProduct.id : "",
        status: this.projectStatus.id
          ? this.projectStatus.id
          : ["active", "inactive", "pendingInactive"],
        broker: this.user.id,
      });
    },

    acceptAlert(color, title, text) {
      this.$vs.notify({ color, title, text });
    },
    checkUserRole(pack) {
      return role[this.user.type][pack];
    },
    lastLogin(data) {
      return mixin.methods.lastLogin(data.lastLoginAt);
    },
    aboutElement(data) {
      return mixin.methods.aboutElement(data);
    },

    exportToData() {
      if (this.selectedFormat == "pdf") {
        let tableToPrintPdf = [];

        this.allProjects.list.forEach((el, index) => {
          let jsonTmp = {
            index: index + 1,
            type: this.profilList.find((type) => type.id == el.type).label,
            firstname: el.person.firstname,
            lastname: el.person.lastname,
            username: el.username,
            email: el.email,
          };
          tableToPrintPdf.push(jsonTmp);
        });

        let headerArray = [];

        this.headerTitle.forEach((title, index) => {
          headerArray.push({ header: title, dataKey: this.headerVal[index] });
        });
        mixin.methods.exportToPdf(
          [...tableToPrintPdf],
          headerArray,
          this.fileName,
          "LES INTERMEDIAIRES"
        );
      } else {
        let tableToPrint = [];
        this.allProjects.list.forEach((el) => {
          let jsonTmp = {
            type: this.profilList.find((type) => type.id == el.type).label,
            lastname: el.person.lastname,
            firstname: el.person.firstname,
            username: el.username,
            email: el.email,
            profession: el.profession,
            status: el.status,
          };
          tableToPrint.push(jsonTmp);
        });

        let headerValTmp = ["type", "firstname", "lastname", "email", "status"];

        let headerTitleTmp = ["Profil", "Prénom(s)", "Nom", "Email", "Statut"];

        mixin.methods.exportToData(
          tableToPrint,
          headerTitleTmp,
          headerValTmp,
          this.fileName,
          this.cellAutoWidth,
          this.selectedFormat
        );
      }
      this.clearFields();
    },
    clearFields() {
      (this.filename = ""),
      (this.fileName = ""),
        (this.cellAutoWidth = true),
        (this.selectedFormat = "xlsx");
    },
    dateElement(data) {
      return mixin.methods.dateElement(data);
    },
    formatDate(data) {
      return mixin.methods.formatDate(data);
    },
    async fetchApplyGetAllProject(data) {
      this.loadingForTable = true;

      try {
        await this.applyGetAllProject(data);

        console.log("this.allProjects", this.allProjects);

        this.total = this.allProjects.total;
        let p = Math.ceil(this.total / this.limit);
        this.allPages = p ? p : 1;

        this.loadingForTable = false;
        this.error = false;
        this.errorMsg = "";
      } catch (err) {
        this.loadingForTable = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Utilisateurs", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Utilisateurs",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },
    projectsSelectedDouble(item) {
      if (this.checkUserRole("project_contrat").show) {
        this.checkViewProject(item);
      } else {
        this.acceptAlert(
          "danger",
          this.role.notAllowTitle,
          this.role.notAllowText
        );
      }
    },
    activeCorrectTabs() {
      switch (this.contratSelected.productName) {
        case "ABQ":
          this.activeTab = 0;

          break;
        case "APF":
          this.activeTab = 1;

          break;
        case "AHR":
          this.activeTab = 2;

          break;
        case "AAE":
          this.activeTab = 3;

          break;

        default:
          break;
      }

      this.popupAllProduct = true;
    },
  },
  watch: {
    endDate() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllProject({
          limit: this.limit,
          skip: this.skip,
          categorySocioProfessional: (this.categorySocPro && this.categorySocPro.id)
            ? this.categorySocPro.id
            : "",
          notice: (this.notice && this.notice.id) ? this.notice.id : "",
          // profession: this.profession,
          startDate: this.startDate,
          endDate: this.endDate,
          familySituation: (this.familySituation && this.familySituation.id)
            ? this.familySituation.id
            : "",
          productName: (this.projetProduct && this.projetProduct.id) ? this.projetProduct.id : "",
          status: (this.projectStatus && this.projectStatus.id)
            ? this.projectStatus.id
            : ["active", "inactive", "pendingInactive"],
          broker: this.user.id,
        });
      }
    },
    startDate() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllProject({
          limit: this.limit,
          skip: this.skip,
          categorySocioProfessional: (this.categorySocPro && this.categorySocPro.id)
            ? this.categorySocPro.id
            : "",
          notice: (this.notice && this.notice.id) ? this.notice.id : "",
          // profession: this.profession,
          startDate: this.startDate,
          endDate: this.endDate,
          familySituation: (this.familySituation && this.familySituation.id)
            ? this.familySituation.id
            : "",
          productName: (this.projetProduct && this.projetProduct.id) ? this.projetProduct.id : "",
          status: (this.projectStatus && this.projectStatus.id)
            ? this.projectStatus.id
            : ["active", "inactive", "pendingInactive"],
          broker: this.user.id,
        });
      }
    },
    categorySocPro() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllProject({
          limit: this.limit,
          skip: this.skip,
          categorySocioProfessional: (this.categorySocPro && this.categorySocPro.id)
            ? this.categorySocPro.id
            : "",
          notice: (this.notice && this.notice.id) ? this.notice.id : "",
          // profession: this.profession,
          startDate: this.startDate,
          endDate: this.endDate,
          familySituation: (this.familySituation && this.familySituation.id)
            ? this.familySituation.id
            : "",
          productName: (this.projetProduct && this.projetProduct.id) ? this.projetProduct.id : "",
          status: (this.projectStatus && this.projectStatus.id)
            ? this.projectStatus.id
            : ["active", "inactive", "pendingInactive"],
          broker: this.user.id,
        });
      }
    },
    notice() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllProject({
          limit: this.limit,
          skip: this.skip,
          categorySocioProfessional: (this.categorySocPro && this.categorySocPro.id)
            ? this.categorySocPro.id
            : "",
          notice: (this.notice && this.notice.id) ? this.notice.id : "",
          // profession: this.profession,
          startDate: this.startDate,
          endDate: this.endDate,
          familySituation: (this.familySituation && this.familySituation.id)
            ? this.familySituation.id
            : "",
          productName: (this.projetProduct && this.projetProduct.id) ? this.projetProduct.id : "",
          status: (this.projectStatus && this.projectStatus.id)
            ? this.projectStatus.id
            : ["active", "inactive", "pendingInactive"],
          broker: this.user.id,
        });
      }
    },
    familySituation() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllProject({
          limit: this.limit,
          skip: this.skip,
          categorySocioProfessional: (this.categorySocPro && this.categorySocPro.id)
            ? this.categorySocPro.id
            : "",
          notice: (this.notice && this.notice.id) ? this.notice.id : "",
          // profession: this.profession,
          startDate: this.startDate,
          endDate: this.endDate,
          familySituation: (this.familySituation && this.familySituation.id)
            ? this.familySituation.id
            : "",
          productName: (this.projetProduct && this.projetProduct.id) ? this.projetProduct.id : "",
          status: (this.projectStatus && this.projectStatus.id)
            ? this.projectStatus.id
            : ["active", "inactive", "pendingInactive"],
          broker: this.user.id,
        });
      }
    },
    projetProduct() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllProject({
          limit: this.limit,
          skip: this.skip,
          categorySocioProfessional: (this.categorySocPro && this.categorySocPro.id)
            ? this.categorySocPro.id
            : "",
          notice: (this.notice && this.notice.id) ? this.notice.id : "",
          // profession: this.profession,
          startDate: this.startDate,
          endDate: this.endDate,
          familySituation: (this.familySituation && this.familySituation.id)
            ? this.familySituation.id
            : "",
          productName: (this.projetProduct && this.projetProduct.id) ? this.projetProduct.id : "",
          status: (this.projectStatus && this.projectStatus.id)
            ? this.projectStatus.id
            : ["active", "inactive", "pendingInactive"],
          broker: this.user.id,
        });
      }
    },
    projectStatus() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllProject({
          limit: this.limit,
          skip: this.skip,
          categorySocioProfessional: (this.categorySocPro && this.categorySocPro.id)
            ? this.categorySocPro.id
            : "",
          notice: (this.notice && this.notice.id) ? this.notice.id : "",
          // profession: this.profession,
          startDate: this.startDate,
          endDate: this.endDate,
          familySituation: (this.familySituation && this.familySituation.id)
            ? this.familySituation.id
            : "",
          productName: (this.projetProduct && this.projetProduct.id) ? this.projetProduct.id : "",
          status: (this.projectStatus && this.projectStatus.id)
            ? this.projectStatus.id
            : ["active", "inactive", "pendingInactive"],
          broker: this.user.id,
        });
      }
    },

    currentPage() {
      this.skip = (this.currentPage - 1) * this.limit;

      this.fetchApplyGetAllProject({
        limit: this.limit,
        skip: this.skip,
        categorySocioProfessional: (this.categorySocPro && this.categorySocPro.id)
          ? this.categorySocPro.id
          : "",
        notice: (this.notice && this.notice.id) ? this.notice.id : "",
        // profession: this.profession,
        startDate: this.startDate,
        endDate: this.endDate,
        familySituation: (this.familySituation && this.familySituation.id)
          ? this.familySituation.id
          : "",
        productName: (this.projetProduct && this.projetProduct.id) ? this.projetProduct.id : "",
        status: (this.projectStatus && this.projectStatus.id)
          ? this.projectStatus.id
          : ["active", "inactive", "pendingInactive"],
        broker: this.user.id,
      });
    },
    limit() {
      this.skip = 0;

      this.fetchApplyGetAllProject({
        limit: this.limit,
        skip: this.skip,
        categorySocioProfessional: (this.categorySocPro && this.categorySocPro.id)
          ? this.categorySocPro.id
          : "",
        notice: (this.notice && this.notice.id) ? this.notice.id : "",
        // profession: this.profession,
        startDate: this.startDate,
        endDate: this.endDate,
        familySituation: (this.familySituation && this.familySituation.id)
          ? this.familySituation.id
          : "",
        productName: (this.projetProduct && this.projetProduct.id) ? this.projetProduct.id : "",
        status: (this.projectStatus && this.projectStatus.id)
          ? this.projectStatus.id
          : ["active", "inactive", "pendingInactive"],
        broker: this.user.id,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.ql-editor {
  height: 100px !important;
}
</style>
